import Rails from '@rails/ujs';
import Swal from 'sweetalert2';

// This is the native confirm, showing a browser alert/confirm dialog
const nativeConfirm = Rails.confirm;

let __SkipConfirmation = false;


function showDialog(element, onConfirm) {
  const options = JSON.parse(element.getAttribute('data-sweet-alert') || '{}')
  const message = element.getAttribute('data-confirm')

  Swal.fire({
    text: message,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: '<span class="text-danger">'+I18n.t("common.delete")+'</span>',
    confirmButtonColor: '#FFFFFF',
    cancelButtonColor: '#3bafda',
    cancelButtonText: I18n.t("common.cancel"),
    reverseButtons: true,
    ...options
  }).then((result) => {
    if (result.isConfirmed) {
      onConfirm()
    }
  })
}

Rails.confirm = function (message, element) {

  // JavaScript is single threaded. We can temporarily change this variable
  // in order to skip out of the confirmation logic.
  //
  // When this function returns true, the event (such as a click event) that
  // sourced it is not prevented from executing whatever it was supposed to
  // trigger, such as a form submission, or following a link.
  if (__SkipConfirmation) {
    return true;
  }

  // This function should be executed when the dialog's positive action is
  // clicked. All it does is re-click the element that was originally
  // triggering this confirmation.
  //
  // Clicking that element will, as expected, re-call Rails.confirm (unless
  // we'd remove [data-confirm] temporarily, which is the alternative solution
  // to this), but because __SkipConfirmation is set, it will bail out early.
  function onConfirm() {
    __SkipConfirmation = true
    element.click()
    __SkipConfirmation = false
  }

  // Here a custom dialog can be shown. use whatever method you like. This
  // hypothetical function shows a dialog.
  //
  showDialog(element, onConfirm)

  // The dialog should, on confirm, call onConfirm()

  // This ensures that the original event that caused this confirmation is
  // swallowed and the action is NOT executed.
  return false;
};