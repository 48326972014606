import CpfToast from "../../utils/checkout/CpfToast";

export default class Location {

    constructor() {
        this.bindEvents();
        this.toast = new CpfToast();
        this.dataLayerCity = {};
        this.dataLayerCode = {};

        window.application.setOnDataChangeListener(this);
    }


    bindEvents() {
        const self = this;
        $('[name="customer[city_id]"]').on('click', (e) => {
            self.setGtmDataLayerCity(e);
            $('#location_form').submit()
        });

        $('.submit-code').on('click', (e) => {
            self.setGtmDataLayerCode(e);
        });
    }

    onDataChanged(data) {
        if(data.dataLayerCity){ this.dataLayerCity = data.dataLayerCity; }
        if(data.dataLayerCode){ this.dataLayerCode = data.dataLayerCode; }
    }

    onDestroy() {
    }

    setGtmDataLayerCity(e) {
        let el = $(e.currentTarget);
        this.dataLayerCity.ecommerce.items[0]['chosen_region'] = el.data('region');

        window.dataLayer.push(this.dataLayerCity);
    }

    setGtmDataLayerCode(e) {
        window.dataLayer.push(this.dataLayerCode);
    }
}