import RecaptchaFix from "../../utils/RecaptchaFix";
import PostalCodeAutocomplete from "../../utils/PostalCodeAutocomplete";

export default class RegistrationsNew {

    constructor() {
        this.recaptchaFix = new RecaptchaFix('#login_customer');
        this.postalCodeAutocomplete = new PostalCodeAutocomplete();
        this.initValidation();
        this.setPasswordVisibility();
        this.bindEvents();
        this.toggleSubmit();
    }


    bindEvents() {
        $('#customer_first_name').off( 'keyup', this.toggleSubmit ).keyup( this.toggleSubmit )
        $('#customer_last_name').off( 'keyup', this.toggleSubmit ).keyup( this.toggleSubmit )
        $('#customer_email').off( 'keyup', this.toggleSubmit ).keyup( this.toggleSubmit )
        $('#customer_register_phone').off( 'keyup', this.toggleSubmit ).keyup( this.toggleSubmit )
        $('#customer_password').off( 'keyup', this.toggleSubmit ).keyup( this.toggleSubmit )
        $('#customer_postal_code').off( 'focusout', this.toggleSubmit ).focusout( this.toggleSubmit )
    }

    onDataChanged(data) {
    }

    onDestroy() {
        this.recaptchaFix.onDestroy();
        this.postalCodeAutocomplete.onDestroy();
    }

    toggleSubmit() {
        const fields = ['customer_first_name', 'customer_last_name', 'customer_email', 'customer_phone', 'customer_password', 'customer_address_postal_code', 'customer_address_city']

        let empty_field = fields.find( f => $(`#${f}`).val().trim() === '' )

        console.log(empty_field)
        if( empty_field === undefined) {
            $('#btn-register').removeClass('disabled')
        } else {
            $('#btn-register').addClass('disabled')
        }
    }

    setPasswordVisibility() {
        $('.js-toggle-password').unbind('click', this.togglePassword ).click( this.togglePassword )
    }

    togglePassword(e) {
        let input = $(e.target).parent().find('input')
        if ( input.attr('type') === 'password' ) {
            input.attr('type', 'text');
            $(e.target).text(I18n.t('common.hide').toUpperCase())
        } else {
            input.attr('type', 'password');
            $(e.target).text(I18n.t('common.show').toUpperCase())
        }
    }

    initValidation() {
        application.setTemplateValidation({
            "customer[first_name]": {
                required: true
            },
            "customer[last_name]": {
                required: true
            },
            "customer[email]": {
                required: true,
                email_validation: true
            },
            "customer[phone]": {
                required: true,
                phone_number_fr: true
            },
            "customer[password]": {
                required: true,
                password_validation: true
            },
            "customer[postal_code]": {
                required: true,
                remote: {
                    url: $('#customer_postal_code').data('urlvalidate'),
                    data: {
                        postal_code: function() {
                            return $('#customer_address_postal_code').val();
                        }
                    }
                }
            }
        }, '#login_customer')
    }
}