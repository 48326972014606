import Toast from "../../utils/Toast";
export default class CartRecap {

    constructor() {
        this.current_promo_code = '';
        this.initMethods();
        this.bindEvents();
        this.initMobileCart();
        this.setupPromoCode();
        //window.application.setOnDataChangeListener(this);
    }

    initMethods() {
        let self = this;
        this.onDataChanged = () => self.toggleCart();
        this.onCloseCart = () => $('#cart-status').prop('checked', false);
        this.onUpdateSuccess = (response) => self.updateSuccess(response);
        this.onUpdateError = (response) => self.updateError(response);
        this.onPromoCodeChanged = (e) => {
            self.togglePromoCodeButton(e)
            self.removeError();
        };
        this.onSendPromoCode = (e) => {
            e.preventDefault();
            self.update({promo_code: $($('.promo-code')[0]).val()}, true, () => {
                self.updateOney('record_payment_installment_count_installment_3x', true, 3)
                    .then(() => self.updateOney('record_payment_installment_count_installment_4x', false, 4));
            });
        }
        this.onRemovePromoCode = (e) => {
            e.preventDefault();
            self.update({promo_code: ''}, true, () => {
                self.updateOney('record_payment_installment_count_installment_3x', true, 3)
                    .then(() => self.updateOney('record_payment_installment_count_installment_4x', false, 4));
            });
        }
    }

    bindEvents() {
        $('#cart-status').change(this.onDataChanged);
        $('#cart-modal').on('hidden.bs.modal', this.onCloseCart);

        this.resetEvents();
    }

    resetEvents() {
        $('.promo-code').off('keyup', this.onPromoCodeChanged).keyup(this.onPromoCodeChanged);
        $('.promo-code-button').off('click', this.onSendPromoCode).click(this.onSendPromoCode);
        $('.remove-promo-code').off('click', this.onRemovePromoCode).click(this.onRemovePromoCode);
    }

    onDataChanged(data) {
    }

    onDestroy() {
        $('#cart-status').off('change', this.onDataChanged);
        $('#cart-modal').off('hidden.bs.modal', this.onCloseCart);
    }

    update(data, has_promo_code = false, callback = () => {}) {

        let self = this;
        this.setLoading();

        data['has_promo_code'] = has_promo_code;
        if ( data['promo_code'] === undefined ) {
            data['promo_code'] = this.current_promo_code;
        }

        $.ajax({
            url: $('.cart-recap').data('url'),
            method: 'POST',
            data: data,
            success: (data) => {
                self.onUpdateSuccess(data);
                callback();
            },
            error: this.onUpdateError
        });
    }

    updateSuccess(response) {
        $('.cart-recap').html(response);
        $('.total-price').html($('p[data-total-price]').data('total-price'));
        this.setupPromoCode();
        $('.cart-recap').trigger('cart:updated');
        this.resetEvents();
        this.removeLoading();
    }

    updateError(response) {
        Toast.error(response.responseJSON.error);
        this.removeLoading();

        setTimeout(function() {
            Turbolinks.visit(response.responseJSON.url);
        }, 3000);
    }

    toggleCart() {
        if ($('#cart-status').is(':checked')) {
            setTimeout(() => this.openCart(), 250);
        } else {
            this.closeCart();
        }
    }

    openCart() {
        $('#cart-modal').modal('show');
    }

    closeCart() {
        $('#cart-modal').modal('hide');
    }

    togglePromoCodeButton(e) {
        let el = $(e.currentTarget);
        if (el.val().length > 0) {
            $('.promo-code-button').prop('disabled', false);
        } else {
            $('.promo-code-button').prop('disabled', true);
        }
        $('.promo-code').val(el.val());
    }

    setLoading() {
        $('.submit-cart').addClass('loading').prop('disabled', true);
    }

    removeLoading() {
        setTimeout(() => $('.submit-cart').removeClass('loading').prop('disabled', false), 250);
    }

    updateOney(elementId, reload, installment_count) {
        return $.ajax({
            url: $(`#${elementId}`).data('url'),
            method: 'GET',
            data: { reload, installment_count },
            success: (data) => {
                $(`#${elementId}_content`).html(data);
            },
            error: (err) => { console.log(err) }
        });
    }

    removeError() {
        $('.promo-code').removeClass('is-invalid');
    }

    initMobileCart() {
        let options = {
            root: null,
            rootMargin: '0px',
            threshold: 1.0
        };

        let callback = (entries, observer) => {
            entries.forEach((entry) => {
                $('.cart-fixed').css('transition', '0.3s, top 0s');
                if (entry.isIntersecting) {
                    $('.cart-fixed').removeClass('scrolling');
                } else {
                    $('.cart-fixed').addClass('scrolling');
                }
                // timeout
                setTimeout(() => {
                    $('.cart-fixed').css('transition', '0.3s');
                }, 100);
            });
        };

        let observer = new IntersectionObserver(callback, options);

        let target = document.querySelector("#cart-spacer");
        observer.observe(target);
    }

    setupPromoCode() {
        this.current_promo_code = $('span[data-promo-code]').data('promo-code')
    }
}