export default class PostalCodeAutocomplete {

    constructor(resource = 'customer', field_name = "postal_code", postal= "#customer_address_postal_code", city = "#customer_address_city") {
        this.resource = resource;
        this.field_name = field_name;
        this.postal = postal;
        this.city = city;
        this.input_element = `#${this.resource}_${this.field_name}`;
        this.$input_element = $(this.input_element);
        this.currentPage = 1;
        this.timeOut = null;

        this.initHtml();
        this.initMethods();
        this.resetAutocomplete();
        this.bindEvents();
        //window.application.setOnDataChangeListener(this);
    }

    bindEvents() {
        let self = this;
        this.$input_element.keyup( this.onKeyup )
        this.$input_element.focus( () => {
            $(`#${self.resource}_${self.field_name}-error`).remove();
            self.$input_element.addClass('show')
            self.resetAutocomplete()
        } )
        $(window).click(() => self.$input_element.removeClass('show'));
        this.$input_element.click( e => e.stopPropagation() )
    }

    resetItemEvents() {
        $('.postal-code-autocomplete-item').off('click', this.onItemClick).click(this.onItemClick)
    }

    onDataChanged(data) {
    }

    onDestroy() {
        this.$input_element.off("keyup", this.onKeyup )
    }

    initMethods() {
        let self = this;
        this.onKeyup = e => {
            if(this.timeOut !== null){
                clearTimeout(this.timeOut);
            }

            if(['Backspace', 'Delete'].includes(e.key)) {
                $(this.postal).val('')
                $(this.city).val('')
            }

            this.timeOut = setTimeout(function () {
                self.resetAutocomplete();
            }, 250);
        }
        this.onItemClick = e => this.setPostalCodeData(e);
        this.successSearch = json => this.buildPage(json);
    }

    resetAutocomplete() {
        this.currentPage = 1;
        this.isReset = true;
        this.getNextPage();
    }

    getNextPage(reset = false) {
        let data = {
            page: this.currentPage,
            term: this.$input_element.val()
        }

        $.ajax({
            url: this.$input_element.data('url'),
            data : data,
            success: this.successSearch
        });
    }

    setPostalCodeData(e){
        let $el = $(e.target)
        $(this.postal).val($el.data('postal-code'))
        $(this.city).val($el.data('city'))
        this.$input_element.val($el.text())
        this.$input_element.trigger('focusout')
    }

    initHtml() {
        this.$input_element.after(`<div class="postal-code-autocomplete"></div>`)
        if($(this.postal).val() !== '' && $(this.city).val() !== '') {
            this.$input_element.val(`${$(this.postal).val()} - ${$(this.city).val()}`)
        }
    }

    buildPage(json) {
        let self = this;

        if(this.isReset) {
            this.$input_element.nextAll('.postal-code-autocomplete').empty();
            this.isReset = false;
        }

        let autocompleteContent = ''
        if(json.results.length === 0) {
            autocompleteContent = this.buildEmpty()
        }else {
            json.results.map((item) => {
                autocompleteContent += self.buildElement(item.postal_code, item.city)
            })
        }
        this.$input_element.nextAll('.postal-code-autocomplete').append(autocompleteContent);

        this.resetItemEvents();
        this.currentPage++;
    }

    buildElement(postal_code, city) {
        return `<div class="postal-code-autocomplete-item" data-postal-code="${postal_code}" data-city="${city}">${postal_code} - ${city}</div>`
    }

    buildEmpty() {
        return `<div class="postal-code-autocomplete-empty">${I18n.t('student.authentication.registrations.new.postal_code_empty')}</div>`
    }
}