import CartRecap from "../../utils/checkout/CartRecap";
import CpfToast from "../../utils/checkout/CpfToast";

export default class Options {

    constructor() {
        this.cart_recap = new CartRecap();
        this.initMethods();
        this.bindEvents();
        this.toast = new CpfToast();
        this.dataLayer = {};

        window.application.setOnDataChangeListener(this);
    }

    initMethods() {
        let self = this;
        this.onOptionChanged = () => self.updateCart();
    }

    bindEvents() {
        let self = this;
        $(`input[name="record[option_ids][]"]`).change(this.onOptionChanged);
        $('.submit-cart').on('click', () => self.setGtmDataLayer());
    }

    onDataChanged(data) {
        if(data.dataLayer){ this.dataLayer = data.dataLayer; }
    }

    onDestroy() {
    }

    updateCart() {
        let option_ids = $(`input[name="record[option_ids][]"]:checked`).map(function () {
            return $(this).val();
        }).get()

        option_ids = option_ids.length > 0 ? option_ids : [''];

        this.cart_recap.update({
            record: {
                option_ids: option_ids
            }
        }, false);
    }

    setGtmDataLayer() {
        window.dataLayer.push(this.dataLayer);
    }
}