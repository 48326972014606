import RecaptchaFix from "../../utils/RecaptchaFix";

export default class SessionsNew {

    constructor() {
        this.recaptchaFix = new RecaptchaFix('#login_customer');
        this.initValidation();
        this.setPasswordVisibility();
        this.bindEvents();
        this.toggleSubmit();
    }


    bindEvents() {
        $('#customer_email').off( 'input', this.toggleSubmit ).on("input", this.toggleSubmit )
        $('#customer_password').off( 'input', this.toggleSubmit ).on("input", this.toggleSubmit )
    }

    onDataChanged(data) {
    }

    onDestroy() {
        this.recaptchaFix.onDestroy();
    }

    toggleSubmit() {
        let email = $('#customer_email').val();
        let password = $('#customer_password').val();
        if( email.trim() !== '' && password.trim() !== '' ) {
            $('#btn-login').removeClass('disabled')
        } else {
            $('#btn-login').addClass('disabled')
        }
    }

    setPasswordVisibility() {
        $('.js-toggle-password').unbind('click', this.togglePassword ).click( this.togglePassword )
    }

    togglePassword(e) {
        let input = $(e.target).parent().find('input')
        if ( input.attr('type') === 'password' ) {
            input.attr('type', 'text');
            $(e.target).text(I18n.t('common.hide').toUpperCase())
        } else {
            input.attr('type', 'password');
            $(e.target).text(I18n.t('common.show').toUpperCase())
        }
    }

    initValidation() {
        application.setTemplateValidation({
            "customer[email]": {
                required: true,
                email_validation: true
            },
            "customer[password]": {
                required: true
            }
        }, '#login_customer')
    }
}