import CpfToast from "../../utils/checkout/CpfToast";

export default class SessionRecap {

    constructor() {
        this.bindEvents();
        this.toast = new CpfToast();
        this.dataLayer = {};

        window.application.setOnDataChangeListener(this);
    }


    bindEvents() {
        $('#add_to_cart').on('click', () => this.setGtmDataLayer() );
    }

    onDataChanged(data) {
        if(data.dataLayer){ this.dataLayer = data.dataLayer; }
    }

    onDestroy() {
    }

    setGtmDataLayer() {
        window.dataLayer.push(this.dataLayer);
    }
}