import Toast from "../Toast";

export default class PayPlugIntegrated {

    constructor() {
        this.integratedPayment = null;
        this.initMethods();
        this.initPayPlugForm();
        this.bindEvents();
        //window.application.setOnDataChangeListener(this);
    }

    initMethods() {
        let self = this;
        this.onFormSubmitted = (e) => self.validateForm(e);
        this.onPaymentCompleted = (e) => self.paymentCompleted(e);
    }

    bindEvents() {
        let self = this;
        $('#integrated-form').submit( this.onFormSubmitted );
    }

    onDataChanged(data) {
    }

    onDestroy() {
        $('#integrated-form').off('submit', this.onFormSubmitted );
    }

    validateForm(event) {
        event.preventDefault();
        this.setLoading();
        this.integratedPayment.validateForm();
    }

    initPayPlugForm() {
        let self = this;
        let fields = ['cardholder', 'pan', 'cvv', 'exp'];

        let isTest= application.env !== 'production';
        this.integratedPayment = new Payplug.IntegratedPayment(isTest);

        fields.forEach(type => {
            this.initField(`${type}-input-container`, type);
        });

        this.integratedPayment.onValidateForm(({isFormValid}) => {
            if (isFormValid) {
                self.createPaymentOnBackEnd();
            } else {
                self.removeLoading();
            }
        });

        this.integratedPayment.setDisplayMode3ds(Payplug.DisplayMode3ds.LIGHTBOX);
        this.integratedPayment.onCompleted(this.onPaymentCompleted);
    }

    payPlugStyles() {
        return {
            default: {
                color: '#1A1A1A',
                fontFamily: 'sans-serif',
                fontSize: '16px'
            },
            invalid: {
                color: '#dc3545',
            }
        }
    }

    payPlugStylesWithPlaceholder(field) {
        let styles = this.payPlugStyles();
        styles['placeholder'] = I18n.t(`student.checkout.payment.integrated_pay_plug_payment.fields.${field}`);
        return styles;
    }

    initField(fieldId, type) {
        let self = this;
        let el = document.getElementById(fieldId);
        let style = this.payPlugStylesWithPlaceholder(type);
        let payPlugEl = this.initPayPlugElement(el, type, style);
        if (payPlugEl == null) { return; }

        payPlugEl.onFocus(() => { el.classList.add('focused') });
        payPlugEl.onBlur(() => { el.classList.remove('focused') });
        payPlugEl.onChange((event) => {
            if(event.error) {
                $(el).after(`<span id="${type}-error" class="is-invalid" style="">${self.errorMessage(event.error.name)}</span>`)
            } else {
                $(`#${type}-error`).remove()
            }
        });
    }

    initPayPlugElement(el, type, style) {
        let iPayment = this.integratedPayment;
        let payPlugMethods = {
            'cardholder': iPayment.cardHolder.bind(iPayment),
            'pan': iPayment.cardNumber.bind(iPayment),
            'cvv': iPayment.cvv.bind(iPayment),
            'exp': iPayment.expiration.bind(iPayment)
        };

        return payPlugMethods[type] ? payPlugMethods[type](el, style) : null;
    }

    errorMessage(errorName) {
        return I18n.t(`student.checkout.payment.integrated_pay_plug_payment.errors.${errorName.toLowerCase()}`);
    }

    submitCardFrom() {
        $('#integrated-form').submit();
    }

    createPaymentOnBackEnd() {
        let self = this;

        $.ajax({
            url: $("#record_payment_installment_count_installment_1x").data("url"),
            method: "POST",
            data: {},
            success: (paymentObject) => {
                self.integratedPayment.pay(paymentObject.payment_id, Payplug.Scheme.AUTO)
            },
            error: (error) => console.error(error)
        })
    }

    paymentCompleted(event) {
        if (event.error) {
            Toast.error(this.errorMessage(event.error.name));
            this.removeLoading();
        } else {
            this.retrievePaymentStatusFromBackEnd(event.token);
        }
    }

    retrievePaymentStatusFromBackEnd(payment_id) {
        let self = this;
        $.ajax({
            url: $("#record_payment_installment_count_installment_1x").data("url-get-status"),
            method: "GET",
            data: {payment_id: payment_id},
            success: (response) => self.paymentStatusRetrieved(response),
            error: (error) => self.paymentStatusError(error)
        })
    }

    paymentStatusRetrieved(response) {
        if (response.status === 'status_validated') {
            Turbolinks.visit(response.url);
        } else if(response.status === 'status_error') {
            application.current_handler.gtmDataLayer.payment_info = {
                payment_method: 'payment_payplug_installment_1x',
                transaction_id: response.transaction_id,
                declined_reason: response.error
            }
            application.current_handler.setGtmDataLayer('payment_declined');
            $('#modal-card-error-message').html(I18n.t(`student.checkout.payment.integrated_pay_plug_payment.card_errors.${response.error}`));
            $('#modal-card-error').modal('show');
        }
        this.removeLoading();
    }

    paymentStatusError(error) {
        console.log(error);
        Turbolinks.visit(location.toString());
        this.removeLoading();
    }


    setLoading() {
        $('.submit-cart').addClass('loading disabled');
    }

    removeLoading() {
        setTimeout(() => $('.submit-cart').removeClass('loading disabled'), 250);
    }
}