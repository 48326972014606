import Toast from "../Toast";
import Cookie from "../../../utils/Cookie";

export default class CpfToast {

    constructor() {
        this.bindEvents();
    }


    bindEvents() {
        if( Cookie.get('toast_cpf') !== 'false') {
            Toast.info(I18n.t('student.checkout.location.toast'), false, 'toast-cpf');
        }

        $('#toast-cpf').on('hide.bs.toast', () => { Cookie.set('toast_cpf', 'false', 365); });
    }

    onDataChanged(data) {
    }

    onDestroy() {
    }
}