import RecaptchaFix from "../../utils/RecaptchaFix";

export default class PasswordEdit {

    constructor() {
        this.recaptchaFix = new RecaptchaFix('#new_customer');
        this.initValidation();
        this.bindEvents();
        this.setPasswordVisibility();
        this.toggleSubmit();
    }


    bindEvents() {
        $('#customer_password').off( 'keyup', this.toggleSubmit ).keyup( this.toggleSubmit )
        $('#customer_password_confirmation').off( 'keyup', this.toggleSubmit ).keyup( this.toggleSubmit )
        $('#btn-update-password').click(() => { document.getElementById('new_customer').reportValidity()})
    }

    onDataChanged(data) {
    }

    onDestroy() {
        this.recaptchaFix.onDestroy();
    }

    toggleSubmit() {
        let password = $('#customer_password').val().trim();
        let passwordConfirmation = $('#customer_password_confirmation').val().trim();
        if( password !== '' && passwordConfirmation !== '' ) {
            $('#btn-update-password').removeClass('disabled')
        } else {
            $('#btn-update-password').addClass('disabled')
        }
    }

    setPasswordVisibility() {
        $('.js-toggle-password').unbind('click', this.togglePassword ).click( this.togglePassword )
    }

    togglePassword(e) {
        let input = $(e.target).parent().find('input')
        if ( input.attr('type') === 'password' ) {
            input.attr('type', 'text');
            $(e.target).text(I18n.t('common.hide').toUpperCase())
        } else {
            input.attr('type', 'password');
            $(e.target).text(I18n.t('common.show').toUpperCase())
        }
    }

    initValidation() {
        application.setTemplateValidation({
            "customer[password]": {
                required: true,
                password_validation: true
            },
            "customer[password_confirmation]": {
                required: true,
                equalTo: '#customer_password'
            },
        }, '#new_customer')
    }
}