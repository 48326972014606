export default class Toast {
    static toastContainer() { return $('.alerts-box-position-fixed'); }

    static success(message, timeout = 5000, id = null) {
        Toast.toastContainer().append( Toast.getToastHtml(message, 'success', timeout, id));
        Toast.showAndDelayHide();
    }

    static error(message, timeout = 5000, id = null) {
        Toast.toastContainer().append(this.getToastHtml(message, 'danger', timeout, id));
        Toast.showAndDelayHide();
    }

    static info(message, timeout = 5000, id = null) {
        Toast.toastContainer().append(this.getToastHtml(message, 'info', timeout, id));
        Toast.showAndDelayHide();
    }

    static warning(message, timeout = 5000, id = null) {
        Toast.toastContainer().append(this.getToastHtml(message, 'warning', timeout, id));
        Toast.showAndDelayHide();
    }

    static showAndDelayHide() {
        let lastToast = Toast.toastContainer().find('.toast:last')
        lastToast.toast('show');
    }

    static getToastHtml(message, type, timeout, id) {
        return `<div ${id ? `id="${id}"` : ''} ${timeout ? `data-bs-delay="${timeout}"` : ''} ${!timeout ? 'data-bs-autohide="false"' : ''} class="alert alert-white-responsive alert-dismissible toast fade" role="alert">
      ${message}
      <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
    </div>`
    }
}