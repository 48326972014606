import RecaptchaFix from "../../utils/RecaptchaFix";

export default class ConfirmationEdit {

    constructor() {
        this.recaptchaFix = new RecaptchaFix('#edit_customer');
        this.initValidation();
        this.bindEvents();
        this.toggleSubmit();
    }


    bindEvents() {
        $('#customer_password').off( 'keyup', this.toggleSubmit ).keyup( this.toggleSubmit )
        $('#customer_password_confirmation').off( 'keyup', this.toggleSubmit ).keyup( this.toggleSubmit )
        $('#customer_email').off( 'keyup', this.toggleSubmit ).keyup( this.toggleSubmit )
        $('#btn-update-password').click(() => { document.getElementById('edit_customer').reportValidity()})
    }

    onDataChanged(data) {
    }

    onDestroy() {
        this.recaptchaFix.onDestroy();
    }

    toggleSubmit() {
        let emailInput = $('#customer_email');
        let submitBtn = $('#btn-update-password');
        let btnDisable = true;

        if( submitBtn.length === 0 ) {
            submitBtn = $('#btn-confirmation');
        }

        if (emailInput.length > 0) {
            let email = emailInput.val().trim();
            btnDisable = email === '';
        } else {
            let password = $('#customer_password').val().trim();
            let passwordConfirmation = $('#customer_password_confirmation').val().trim();
            btnDisable = password === '' || passwordConfirmation === '' ;
        }

        if( btnDisable ) {
            submitBtn.addClass('disabled')
        } else {
            submitBtn.removeClass('disabled')
        }
    }

    initValidation() {
        application.setTemplateValidation({
            "customer[password]": {
                required: true,
                password_validation: true
            },
            "customer[password_confirmation]": {
                required: true,
                equalTo: '#customer_password'
            },
        }, '#edit_customer')
    }
}