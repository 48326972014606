export default class TemplateValidation{

    constructor(validationRules, form_element = '#crud_form') {
        this.validationRules = validationRules;
        this.form_element = form_element;
        this.validation = null;

        this.addCustomValidations();
        this.initValidation();
    }

    onDestroy(){
        if(this.validation !== undefined && this.validation !== null){
            this.validation.destroy();
        }
    }

    addCustomValidations() {

        // Validate french phone number: 10 chars type numbers ignoring spaces
        $.validator.addMethod("phone_number_fr", function( value, element ) {
            let val = value.replace(/ /g, '');
            return val.match(/[0-9]/g) && value.replace(/ /g, '').length === 10 && (value.charAt(0) === "0");
        }, I18n.t('common.js_validation.phone_number_fr'));

        // Validate passwords: 8 to 70 chars containing at least 1 majus, 1 minus, 1 number and 1 special char
        $.validator.addMethod("password_validation", function( value, element ) {
            if ( value === undefined || value === '' ){
                return true;
            }
            return value.match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*()_+{}|:<>?\/~`\-=\[\]';.,]).{8,70}$/g) && value.length >= 8;
        }, I18n.t('common.js_validation.password_validation'));

        // Validate correct email: {some_chars}@{some_chars}.{2 to 4 chars}
        $.validator.addMethod("email_validation", function( value, element ) {
            return  value.match(/^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/);
        }, I18n.t('common.js_validation.email_validation'));

        // Validate wysiwyg max content: 255 max
        $.validator.addMethod("wysiwyg_255", function( value, element ) {
            let n_characters = (value !== '' ? value.length : 0)
            return  n_characters < 255;
        }, I18n.t('common.js_validation.wysiwyg_255_validation'));

        // Validate neph code: 12 chars type numbers
        $.validator.addMethod("neph", function( value, element ) {
            if( value === '') { return true }
            return value.match(/^(0[0-9]|[1-9][0-9])((0[1-9])|(1[0-2]))((0[1-9])|([1-8][0-9])|(9[0-5]))((0[0-9])|([1-9][0-9]))([0-9]{4})$/);
        }, I18n.t('common.js_validation.neph'));

        $.validator.addMethod("greater_than", function(value, element, params) {
            let $paramEl = $(params);
            if ($paramEl.val() !== '') {
                if (!/Invalid|NaN/.test(new Date(value))) {
                    return new Date(value) > new Date($paramEl.val());
                }
                return isNaN(value) && isNaN($paramEl.val()) || (Number(value) > Number($paramEl.val()));
            }
            return true;
        },I18n.t('common.js_validation.greater_than'));

        $.validator.addMethod("unique_code_option", function(value, element, params) {
            let $paramEl = $(element);
            let $optionContainer = $("#option_card");
            let $optionInputs = $optionContainer.find('input[name*="code"]');
            let $optionValues = $optionInputs.map(function() { return this.value; }).get();

            let $optionValuesUnique = $optionValues.filter(function(item, pos) {
                return item === $paramEl.val()
            })
            return $optionValuesUnique.length === 1;
        }, I18n.t('common.js_validation.unique_code_option'));

        $.validator.addMethod("responsible_email_different", function(value, element, param) {
            // Check if the value of the current field is different from the value of the other field
            console.log("responsible_email_different called");
            console.log(value);
            console.log("----");
            console.log($(param).val());
            return this.optional(element) || value !== $(param).val();
        }, "L'email du responsable légal doit être différent du vôtre.");
    }

    initValidation() {
        let options = {
            focusCleanup: false,
            rules: this.validationRules,
            validClass: "is-valid",
            errorClass: "is-invalid",
            errorElement: "span",
            errorPlacement: this.errorPlacement,
            highlight: this.highlight,
            unhighlight: this.unhighlight,
            ignore: "[contenteditable='true']:not([name]), :hidden"
        }

        if(I18n.locale !== 'en'){
            options.messages = require('jquery-validation/dist/localization/messages_'+I18n.locale)
        }
        this.validation = $(this.form_element).validate(options);
    }

    errorPlacement(error, element) {
        if(element.data('error-after') !== null && element.data('error-after') !== undefined){
            $(element.data('error-after')).after(error);
        } else {
            error.appendTo(element.parent());
        }
    }

    highlight(element, errorClass, validClass){
        let element_id = $(element).addClass(errorClass).attr('id');
        $('label[for='+ element_id +']').addClass('text-danger');
    }

    unhighlight(element, errorClass, validClass){
        let element_id = $(element).removeClass(errorClass).attr('id');
        $('label[for='+ element_id +']').removeClass('text-danger');
    }
}