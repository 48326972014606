import CpfToast from "../../utils/checkout/CpfToast";
import Toast from "../../utils/Toast";

export default class SessionDate {

    constructor() {
        this.initMethods();
        this.bindEvents();
        this.toast = new CpfToast();
        this.dataLayer = {};
        this.page = 2;

        window.application.setOnDataChangeListener(this);
    }

    initMethods() {
        const self = this;
        this.onSessionClick = (e) => { self.sendSession(); }
        this.onRequestClick = (e) => {
            self.setGtmDataLayer()
            self.createRequest(e)
        };
        this.onLoadMoreClick = (e) => { self.loadMore(); }

    }

    bindEvents() {
        const self = this;
        $('[name="record[session_id]"]').on('click', this.onSessionClick );
        $('#request-button').click(this.onRequestClick);
        $('#load-more').click(this.onLoadMoreClick);
    }

    onDataChanged(data) {
        if(data.dataLayer){ this.dataLayer = data.dataLayer; }
    }

    onDestroy() {
    }

    createRequest(e) {
        let el = $(e.currentTarget);
        let url = el.data('url');

        $.ajax({
            url: url,
            type: 'POST',
            success: this.showSuccess,
        });
    }

    showSuccess() {
        $("#card-create-notification").addClass('d-none');
        $("#card-create-notification-success").removeClass('d-none');
        //Turbolinks.visit($('#request-button').data('success-url'));

        // setTimeout(() => {
        //     Toast.success(I18n.t('student.checkout.session_date_empty.inscription_message'));
        // }, 500);
    }

    setGtmDataLayer() {
        window.dataLayer.push(this.dataLayer);
    }

    loadMore() {
        let self = this;
        $.ajax({
            url: window.location.href,
            type: 'GET',
            data: { page: this.page },
            success: (data) => {
                self.appendSessions(data)
            },
        });
    }

    sendSession() {
        this.setGtmDataLayer()
        $('#session_date_form').submit()
    }

    appendSessions(data) {
        $('#session-list').append(data);
        this.page++;
        // get last .page and check data-has-more is true
        let lastPage = $('.page').last();
        if (lastPage.data('has-more') === false) {
            $('#load-more').addClass('d-none');
        }
        $('[name="record[session_id]"]').off('click', this.onSessionClick).on('click', this.onSessionClick );
    }
}