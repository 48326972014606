import RecaptchaFix from "../../utils/RecaptchaFix";

export default class PasswordNew {

    constructor() {
        this.recaptchaFix = new RecaptchaFix('#new_customer');
        this.initValidation();
        this.bindEvents();
        this.toggleSubmit();
    }


    bindEvents() {
        $('#customer_email').off( 'keyup', this.toggleSubmit ).keyup( this.toggleSubmit )
        $('#btn-login').click(() => { document.getElementById('new_customer').reportValidity()})
    }

    onDataChanged(data) {
    }

    onDestroy() {
        this.recaptchaFix.onDestroy();
    }

    toggleSubmit() {
        let email = $('#customer_email').val().trim();
        if( email !== '' ) {
            $('#btn-login').removeClass('disabled')
        } else {
            $('#btn-login').addClass('disabled')
        }
    }

    initValidation() {
        application.setTemplateValidation({
            "customer[email]": {
                required: true,
                email_validation: true
            },
        }, '#new_customer')
    }
}