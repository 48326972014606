import CpfToast from "../../utils/checkout/CpfToast";

export default class Sessions {

    constructor() {
        this.bindEvents();
        this.toast = new CpfToast();
        this.dataLayer = {};

        window.application.setOnDataChangeListener(this);
    }


    bindEvents() {
        $('[name="customer[formula_id]"]').on('click', this.onFormulaClick );
        $('#session_form').on('submit', () => this.setGtmDataLayer() );
    }

    onDataChanged(data) {
        if(data.dataLayer){ this.dataLayer = data.dataLayer; }
    }

    onDestroy() {
    }

    onFormulaClick(e) {
        let $this = $(e.currentTarget);
        let needConfirm = $this.data('need-confirm');
        let formulaType = $this.data('formula-type');

        if (needConfirm) {
            let message = I18n.t(`student.checkout.sessions.modal_confirm.${formulaType}.label`)
            $('#modal_confirm .modal-body label').text(message)
            $('#modal_confirm').modal('show')
        }else {
            $('#session_form').submit()
        }
    }

    setGtmDataLayer() {
        let selectedFormula = $('[name="customer[formula_id]"]:checked');
        let formulaId = selectedFormula.val();
        let formulaName = selectedFormula.data('name');
        let formulaCategory = selectedFormula.data('category');
        let formulaVariant = selectedFormula.data('variant');

        this.dataLayer.ecommerce.items[0]['item_id'] = formulaId;
        this.dataLayer.ecommerce.items[0]['item_name'] = formulaName;
        this.dataLayer.ecommerce.items[0]['item_category'] = formulaCategory;
        this.dataLayer.ecommerce.items[0]['item_variant'] = formulaVariant;

        window.dataLayer.push(this.dataLayer);
    }
}