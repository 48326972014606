import CpfToast from "../../utils/checkout/CpfToast";

export default class LocationDepartment {

    constructor() {
        this.bindEvents();
        this.toast = new CpfToast();
        this.dataLayerDepartment = {};

        this.toggleNextButton();
        window.application.setOnDataChangeListener(this);
    }


    bindEvents() {
        const self = this;
        $('input[name="customer[department_ids][]"]').on('change', () => {
            self.toggleNextButton();
        });
        $('#location_form').on('submit', (e) => {
            self.setGtmDataLayerDepartment(e);
        });
    }

    onDataChanged(data) {
        if(data.dataLayerDepartment){ this.dataLayerDepartment = data.dataLayerDepartment; }
    }

    onDestroy() {
    }

    setGtmDataLayerDepartment(e) {
        window.dataLayer.push(this.dataLayerDepartment);
    }

    toggleNextButton() {
        // get if any is checked customer[department_ids][]
        const checked = $('input[name="customer[department_ids][]"]:checked').length;
        if(checked === 0) {
            $('button[type=submit]').addClass('disabled');
        } else {
            $('button[type=submit]').removeClass('disabled');
        }
    }
}