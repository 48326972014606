import PostalCodeAutocomplete from "../../utils/PostalCodeAutocomplete";
export default class InitialAssessmentInformations {

    constructor() {
        this.customer_is_under_18 = false;
        this.postalCodeAutocomplete = new PostalCodeAutocomplete();
        this.compute_age();
        this.initValidation();
        this.bindEvents();
    }


    bindEvents() {
        var self = this;

        $('input[name="customer[birth_date]"]').on("change", function() {
            self.compute_age(); 
            self.initValidation();
        });
    }

    compute_age() {
        if($('input[name="customer[birth_date]"]').val() == "") {
            this.customer_is_under_18 = false;
        }
        console.log("birth date changed");
        //Compute age from birth date
        var today = new Date();
        var birthDate = new Date($('input[name="customer[birth_date]"]').val());
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        if (age < 18) {
            console.log("Less than 18");
            this.customer_is_under_18 = true;
        }
        else {
            console.log("More than 18");
            this.customer_is_under_18 = false;
        }

        console.log("age -> " + age);
    }

    onDataChanged(data) {
    }

    onDestroy() {
        this.postalCodeAutocomplete.onDestroy();
    }

    initValidation() {
        const base_rules = {
            "customer[first_name]": {
                required: true
            },
            "customer[last_name]": {
                required: true
            },
            "customer[email]": {
                required: true,
                email_validation: true
            },
            "customer[phone]": {
                required: true,
                phone_number_fr: true
            },
            "customer[birth_date]": {
                required: true
            },
            "customer[birth_place]": {
                required: true
            },
            "customer[nationality]": {
                required: true
            },
            "customer[address_line1]": {
                required: true
            },
            "customer[postal_code]": {
                required: true,
                remote: {
                    url: $('#customer_postal_code').data('urlvalidate'),
                    data: {
                        postal_code: function() {
                            return $('#customer_address_postal_code').val();
                        }
                    }
                }
            },
        };

        let rules = Object.assign({}, base_rules);

        if(this.customer_is_under_18) {
            $("#responsible_part").removeClass("d-none");
            rules["customer[responsible_first_name]"] = {
                required: true
            };

            rules["customer[responsible_last_name]"] = {
                required: true
            };

            rules["customer[responsible_email]"] = {
                required: true,
                email_validation: true,
                responsible_email_different: "#customer_email"
            };
        }
        else {
            $("#responsible_part").addClass("d-none");
            $('input[name="customer[responsible_first_name]"]').removeAttr('required');
            $('input[name="customer[responsible_last_name]"]').removeAttr('required');
            $('input[name="customer[responsible_email]"]').removeAttr('required');
        }
        application.setTemplateValidation(rules, '#infos_form')
    }
}