export default class Cpf {

    constructor() {
        this.bindEvents();
        //window.application.setOnDataChangeListener(this);
    }


    bindEvents() {
    }

    onDataChanged(data) {
    }

    onDestroy() {
    }

    submitPayment() {
        let self = this;
        $.ajax({
            url: $("#record_payment_installment_count_installment_1x").data("url"),
            method: "POST",
            data: {},
            success: (data) => {
                self.onPaymentCreated(data)
            },
            error: (error) => console.log(error)
        })
    }

    onPaymentCreated(data) {
        let modal_el = $('#modal-cpf');
        let cart_el = $('.cart-fixed');

        $('#modal-cpf-link').attr('href', data.url);
        modal_el.modal('show');
        cart_el.css('z-index', '1050')
        modal_el.on('hide.bs.modal', function (e) {
            cart_el.css('transition', '0.3s').css('z-index', '1051')
        })
    }
}