import Cookie from "../../../utils/Cookie";

export default class InitialAssessmentIndex {

    constructor() {
        if($('.preview-player').length>0) {
            this.gifDuration = 0;
            this.setMethods();
            this.bindEvents();
            this.calculateGifDuration();
        }

        this.bindOtherEvents();
    }

    bindOtherEvents() {
        $('input.cumulable').on("change", function() {
            console.log("changed called on cumulable input")
            if ($(this).is(':checked')) {
                $('input.non-cumulable').prop('checked', false);
            }
        });

        $('input.non-cumulable').on("change", function() {
            console.log("changed called on non cumulable input")
            if ($(this).is(':checked')) {
                $('input.cumulable').prop('checked', false);
            }
        });
    }

    setMethods() {
        let self = this;
        this.clickGifPreview = (e) => {
            self.setGifPlayer(e)
        };
    }

    bindEvents() {
        //let src = $('.preview-player').data('src')
        let qId = $('.preview-player').data('qid')
        $('.preview-player').off("click");

        if ( Cookie.get('preview_q'+qId ) === null ) {
            $('.preview-player').one('click', this.clickGifPreview );
        }
        else {
            $("#answer_form").removeClass("d-none");
        }
    }

    setGifPlayer(e) {
        console.log("setGifPlayer called");
        let $el = $('.preview-player');
        let src = $el.data('src');
        let qId = $el.data('qid')
        // the img tag is the firs element from parent
        let player = $el.parent().find('img.player');
        player.attr('src', src);

        setTimeout(() => {
            player.attr('src', '');
            $el.addClass('viewed');
            $el.data('src', '');
            Cookie.set('preview_q'+qId, 'true', 30);
            $("#answer_form").removeClass("d-none");
        }, this.gifDuration * 1000);
    }

    calculateGifDuration() {
        let url = $('.preview-player').data('src')
        let self = this;
        let image = new Image();
        image.src = url;
        fetch(url)
            .then(res => res.arrayBuffer())
            .then(ab => self.isGifAnimated(ab))
            .then(seconds => self.gifDuration = seconds)
    }

    isGifAnimated(arrayBuffer) {
        return new Promise((resolve, reject) => {
            try {
                let arr = new Uint8Array(arrayBuffer);
                let duration = 0;
                for (var i = 0; i < arr.length; i++) {
                    if (arr[i] == 0x21
                        && arr[i + 1] == 0xF9
                        && arr[i + 2] == 0x04
                        && arr[i + 7] == 0x00) {
                        const delay = (arr[i + 5] << 8) | (arr[i + 4] & 0xFF)
                        duration += delay < 2 ? 10 : delay;
                    }
                }
                console.log("gifDuration -> " + (duration / 100));
                resolve(duration / 100);
            } catch (e) {
                reject(e);
            }
        });
    }
}