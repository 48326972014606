export default class CustomSelect {

    constructor( element ) {
        this.element = element;
        this.element_options = this.getSelectOptions(this.element);
        this.initMethods();
        this.init();
        this.bindEvents();
    }

    bindEvents() {
        let self = this;
        $(this.element).mousedown( e => {
            if( window.innerWidth > 768 ) {
                e.preventDefault()
                self.onSelectClick(e);
                setTimeout(function() {
                    $(window).unbind('click', self.closeSelect).click(self.closeSelect);
                }, 300);
            }
        } )
    }

    reBindEvents() {
        $(this.element)
            .next()
            .find('.form-custom-select-dropdown-item')
            .off('click', this.onItemClick).click(this.onItemClick)
    }

    initMethods() {
        let self = this;
        this.onSelectClick = e => self.openSelect(e);
        this.closeSelect = e => {
            self.element.classList.remove('show')
            $(window).unbind('click', self.closeSelect)
        }
    }

    init() {
        let autocompleteContent = ''
        this.element_options.forEach(option => {
            autocompleteContent += this.buildElement(option.value, option.label)
        })
        $(this.element).after(`<div class="form-custom-select-dropdown">${autocompleteContent}</div>`)
        this.reBindEvents();
    }

    getSelectOptions( select ) {
        let options = [];
        $(select).find("option").each(function() {
            options.push({
                value: $(this).val(),
                label: $(this).html()
            });
        });
        return options;
    }

    onDestroy() {
    }

    buildElement(value, label) {
        return `<div class="form-custom-select-dropdown-item" data-value="${value}">${label}</div>`
    }

    openSelect(e) {
        e.stopPropagation()
        this.element.classList.add('show')
    }

    onItemClick(e) {
        let value = $(e.target).data('value')
        let select = $(e.target).parent().parent().find('select')

        select.val(value)
        select.trigger('change')
        select.removeClass('show')
    }
}