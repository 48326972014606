export default class Oney {

    constructor() {
        this.bindEvents();
        //window.application.setOnDataChangeListener(this);
    }


    bindEvents() {
    }

    onDataChanged(data) {
    }

    onDestroy() {
    }

    submitPayment() {
        let self = this;

        $.ajax({
            url: $("#record_payment_installment_count_installment_1x").data("url"),
            method: "POST",
            data: {},
            success: (data) => {
                self.onPaymentCreated(data)
            },
            error: (error) => console.log(error)
        })
    }

    onPaymentCreated(data) {
        if (data.url !== undefined) {
            let modal_el = $('#modal-oney');
            let cart_el = $('.cart-fixed');

            $('#modal-oney-link').attr('href', data.url);
            modal_el.modal('show');
            cart_el.css('z-index', '1050')
            modal_el.on('hide.bs.modal', function (e) {
                cart_el.css('transition', '0.3s').css('z-index', '1051')
            })
        }
    }
}