export default class RecaptchaFix {
    constructor(form_element = '#crud_form') {
        this.form_element = form_element
        this.$form_element = $(this.form_element);
        this.bindEvents();
    }

    bindEvents() {
        let self = this;
        this.$form_element.submit(this.submit_with_validations);
        window.onCompleted = function() {
            console.log('captcha completed.');
            $(self.form_element).submit();
        }
    }

    onDestroy() {
        this.$form_element.off("submit", this.submit_with_validations);
    }

    submit_with_validations(e) {
        console.log('form submitted.');
        if (!grecaptcha.getResponse()) {
            console.log('captcha not yet completed.');
            event.preventDefault(); //prevent form submit
            grecaptcha.execute();
        } else {
            console.log('form really submitted.');
        }
    }
}